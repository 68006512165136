import React from 'react';
import { Box, Typography } from '@mui/material';
import BookingWidget from '../components/BookingWidget'; // Assuming BookingWidget is saved in a components folder

const Contact = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
        padding: '2rem',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        We’d love to hear from you! Reach out with any questions or project inquiries.
      </Typography>

      {/* SimplyBook.me Appointment Widget */}
      <Box
        sx={{
          width: '100%',
          maxWidth: '600px',
          marginTop: '2rem',
          marginBottom: '2rem',
          border: '1px solid #ddd',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <BookingWidget />
      </Box>

      {/* Display Email Address */}
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
        Or email us directly at: <a href="mailto:ns.tech.guru@gmail.com">ns.tech.guru@gmail.com</a>
      </Typography>
    </Box>
  );
};

export default Contact;
