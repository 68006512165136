// src/pages/About.js
import React, { useEffect } from 'react';
import { Box, Typography, Container, Grid, Paper } from '@mui/material';

const About = () => {
    useEffect(() => {
        // Initialize the adsbygoogle array if it's not already present
        if (window.adsbygoogle) {
            window.adsbygoogle.push({});
        }
    }, []);

    return (
        <Container sx={{ py: 4, minHeight: '100vh' }}>
            <Typography variant="h4" align="center" gutterBottom>
                About NetSuite Master
            </Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>
                Your trusted partner for NetSuite solutions and consulting.
            </Typography>

            <Box sx={{ mt: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Our Mission
                        </Typography>
                        <Typography variant="body1">
                            At NetSuite Master, we aim to empower businesses by optimizing and enhancing their NetSuite implementations. With a focus on customization, automation, and seamless integration, we provide end-to-end solutions to help companies streamline operations and achieve their business goals.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Why Choose Us
                        </Typography>
                        <Typography variant="body1">
                            With years of experience in NetSuite development and consulting, we understand the challenges businesses face. Our tailored approach ensures that each solution is built specifically for your unique needs. We’re here to help you maximize your investment in NetSuite, improving efficiency, productivity, and ROI.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            {/* AdSense Section */}
            <Box sx={{ mt: 6, textAlign: 'center' }}>
                <Paper elevation={3} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
                    <Typography variant="h6" gutterBottom>
                        Sponsored Content
                    </Typography>
                    {/* AdSense Code */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <ins
                            className="adsbygoogle"
                            style={{ display: 'block', width: '100%', maxWidth: '728px', height: '90px' }}
                            data-ad-client="ca-pub-6218167737521536"
                            data-ad-slot="YOUR_AD_SLOT"
                            data-ad-format="auto"
                            data-full-width-responsive="true"
                        ></ins>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
};

export default About;
