import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                bgcolor: 'primary.main',
                color: 'white',
                p: 2,
                textAlign: 'center',
                mt: 'auto', // Pushes the footer to the bottom
            }}
        >
            <Typography variant="body2">
                &copy; 2024 NetSuite Master Technical Consultant | All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
