import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Box component="img" src="/netsuite-master-icon.png" alt="Netsuite Master Logo" sx={{ height: 40, marginRight: 2 }} />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    NetSuite Master
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Home</Link>
                    <Link to="/about" style={{ textDecoration: 'none', color: 'white' }}>About</Link>
                    <Link to="/contact" style={{ textDecoration: 'none', color: 'white' }}>Contact</Link>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
