const services = [
    {
        title: 'SuiteTalk Integrations',
        description: 'Integrate with external systems via SuiteTalk.',
        points: [
            'Seamlessly connect with external ERP systems',
            'Utilize REST and SOAP APIs for data exchange',
            'Automate data syncing across platforms',
            'Ensure data consistency across systems',
            'Reduce manual import/export tasks'
        ]
    },
    {
        title: 'Building SOAP WSDL Based Integrations',
        description: 'Develop and manage integrations using SOAP and WSDL standards.',
        points: [
            'Design SOAP web services with WSDL for interoperability',
            'Ensure seamless communication with third-party systems',
            'Build resilient integrations with error handling',
            'Map complex data structures between systems',
            'Implement security protocols for data exchange'
        ]
    },
    {
        title: 'Building NetSuite APIs for Integration with Salesforce',
        description: 'Develop robust APIs to connect with Salesforce and others.',
        points: [
            'Enable seamless data sharing with Salesforce',
            'Build custom RESTful APIs for integration',
            'Support bidirectional data flow',
            'Automate data sync between platforms',
            'Ensure secure and scalable API architecture'
        ]
    },
    {
        title: 'Building APIs in Java',
        description: 'Develop robust APIs using Java for backend integrations.',
        points: [
            'Create RESTful and SOAP APIs for various services',
            'Implement data validation and error handling',
            'Optimize API performance for high traffic',
            'Secure APIs with authentication and authorization',
            'Integrate APIs with databases and other backends'
        ]
    },
    {
        title: 'Building APIs in JavaScript/Node.js',
        description: 'Build efficient APIs in Node.js for scalable web applications.',
        points: [
            'Use Express.js for API routing and middleware',
            'Create real-time functionalities with WebSocket',
            'Integrate with NoSQL and SQL databases',
            'Implement authentication with JWT and OAuth',
            'Ensure scalability and load balancing'
        ]
    },
    {
        title: 'Building NetSuite Docker Containers',
        description: 'Create Docker containers for NetSuite-related development.',
        points: [
            'Containerize NetSuite development environment',
            'Enable portability and ease of deployment',
            'Isolate environments for different projects',
            'Facilitate integration testing with Docker',
            'Streamline DevOps for faster deployments'
        ]
    },
    {
        title: 'Integrating NetSuite with Other Systems using Docker',
        description: 'Use Docker to facilitate NetSuite integrations.',
        points: [
            'Enable API-based integration with Docker',
            'Manage cross-platform data exchange',
            'Automate deployment with Docker containers',
            'Use Docker for efficient resource allocation',
            'Implement scalable, containerized solutions'
        ]
    },
    {
        title: 'Salesorder Automation',
        description: 'Automate sales order processes to increase efficiency.',
        points: [
            'Eliminate manual entry errors',
            'Reduce order processing time',
            'Automate order approvals and workflows',
            'Integrate with CRM for seamless sales flow',
            'Track and update order status automatically'
        ]
    },
    {
        title: 'Invoice Processing',
        description: 'Streamline invoice management and processing.',
        points: [
            'Automate invoice creation from sales data',
            'Reduce delays in invoice dispatching',
            'Ensure consistent invoicing across departments',
            'Enable electronic and bulk invoicing',
            'Improve accuracy and record-keeping for audits'
        ]
    },
    {
        title: 'Generating Quotes',
        description: 'Easily generate accurate quotes for customers.',
        points: [
            'Customize quotes based on pricing rules',
            'Integrate discounting and pricing tiers',
            'Generate quick and accurate quotes',
            'Easily convert quotes to orders',
            'Track and analyze quote conversion rates'
        ]
    },
    {
        title: 'SuiteScript Customization',
        description: 'Customize NetSuite functionality with SuiteScript.',
        points: [
            'Develop custom workflows and automation',
            'Enhance reporting and analytics capabilities',
            'Automate repetitive tasks and processes',
            'Build custom scripts for unique business needs',
            'Integrate third-party applications'
        ]
    },
    {
        title: 'Advanced HTML Email Templates',
        description: 'Create customized email templates for communication.',
        points: [
            'Personalize email communication with customers',
            'Design branded, responsive email templates',
            'Automate email workflows for marketing',
            'Track email performance with analytics',
            'Increase engagement with targeted messaging'
        ]
    },
    {
        title: 'Sending Email with Invoice Attachments',
        description: 'Automate emailing invoices with attachments.',
        points: [
            'Send invoices automatically upon order completion',
            'Attach PDFs directly from NetSuite records',
            'Customize email content and format',
            'Track email delivery and open rates',
            'Reduce time spent on manual invoice distribution'
        ]
    },
    {
        title: 'Emailing Quotes with Attachments',
        description: 'Send quotes to clients with file attachments.',
        points: [
            'Automate quote dispatch upon request',
            'Attach detailed pricing and product information',
            'Use branded email templates for quotes',
            'Track client interactions with sent quotes',
            'Reduce response time for quote inquiries'
        ]
    },
    {
        title: 'Setting NetSuite’s External ID Automatically using SuiteScript',
        description: 'Set up automated management of external IDs for integration.',
        points: [
            'Automate External ID assignment with SuiteScript',
            'Ensure unique identifiers for each record',
            'Enhance data consistency in integrations',
            'Reduce manual ID entry errors',
            'Enable seamless integration with other platforms'
        ]
    },
    {
        title: 'Mass Update NetSuite Records',
        description: 'Bulk update records such as shipment status with SuiteScript.',
        points: [
            'Update multiple records efficiently',
            'Automate shipment status updates',
            'Reduce manual workload on recurring tasks',
            'Schedule mass updates at off-peak hours',
            'Ensure data consistency across large datasets'
        ]
    },
    {
        title: 'Adding Custom Buttons and Fields in Records',
        description: 'Add custom buttons and fields to records like Sales Orders.',
        points: [
            'Enhance functionality with custom buttons',
            'Trigger actions based on field updates',
            'Support custom workflows in records',
            'Enable dynamic field calculations',
            'Integrate custom fields with other systems'
        ]
    },
    {
        title: 'Updating Item Vendor Purchase Price List',
        description: 'Keep vendor price lists updated with automation.',
        points: [
            'Automate price updates for inventory items',
            'Schedule updates to reflect vendor changes',
            'Track price changes over time',
            'Ensure accuracy in purchase orders',
            'Streamline vendor price list management'
        ]
    },
    {
        title: 'Automating Invoice Email Notifications',
        description: 'Set up automated emails for invoice notifications.',
        points: [
            'Notify customers upon invoice generation',
            'Include invoice PDFs in emails',
            'Customize email templates per customer',
            'Track delivery and engagement of emails',
            'Ensure timely invoice dispatch'
        ]
    },
    {
        title: 'Updating Fields on Page Actions',
        description: 'Use SuiteScript to update fields on page load or submit.',
        points: [
            'Trigger field updates on page load',
            'Set default values dynamically',
            'Validate input fields before submission',
            'Ensure data integrity on critical records',
            'Automate calculations during form interactions'
        ]
    },
];

export default services;
