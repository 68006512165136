import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Box, Typography, Pagination, Stack, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText } from '@mui/material';
import Fuse from 'fuse.js';
import servicesData from '../data/services';

const ITEMS_PER_PAGE = 8;

const HomePage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredServices, setFilteredServices] = useState(servicesData);
  const [open, setOpen] = useState(false); // Modal state
  const [selectedService, setSelectedService] = useState(null);

  // Memoize Fuse.js instance
  const fuse = useMemo(() => new Fuse(servicesData, {
    keys: ['title', 'description'],
    includeScore: true,
    threshold: 0.4,
    distance: 100,
    minMatchCharLength: 2,
  }), []);

  useEffect(() => {
    if (searchQuery) {
      const results = fuse.search(searchQuery).map(result => result.item);
      setFilteredServices(results);
    } else {
      setFilteredServices(servicesData);
    }
    setCurrentPage(1); // Reset to first page when search query changes
  }, [searchQuery, fuse]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Open modal with selected service details
  const handleCardClick = (service) => {
    setSelectedService(service);
    setOpen(true);
  };

  // Close modal
  const handleClose = () => {
    setOpen(false);
    setSelectedService(null);
  };

  const totalPages = Math.ceil(filteredServices.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const displayedServices = filteredServices.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ padding: '2rem', flexGrow: 1 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Welcome to NetSuite Master
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Your trusted source for NetSuite solutions and consulting.
        </Typography>

        {/* Search Bar */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <input
            type="text"
            placeholder="Search for services or use cases..."
            value={searchQuery}
            onChange={handleSearchChange}
            style={{
              width: '80%',
              maxWidth: '600px',
              padding: '0.75rem',
              fontSize: '1rem',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          />
        </Box>

        {/* Title for the Services Section */}
        <Typography variant="h5" align="center" gutterBottom>
          Our Services
        </Typography>

        {/* Displaying filtered services in a grid of cards */}
        <Grid container spacing={3} justifyContent="center">
          {displayedServices.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Box
                sx={{
                  padding: '1.5rem',
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleCardClick(service)}
              >
                <Typography variant="h6">{service.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {service.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Pagination Component */}
        <Stack spacing={2} alignItems="center" sx={{ marginTop: '2rem' }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            shape="rounded"
            variant="outlined"
            size="large"
          />
        </Stack>
      </Box>

      {/* Modal for Service Details */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{selectedService?.title}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>{selectedService?.description}</Typography>
          <List>
            {selectedService?.points.map((point, index) => (
              <ListItem key={index}>
                <ListItemText primary={`• ${point}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default HomePage;
